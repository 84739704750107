import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialHighlightState = { highlightsByNode: { highlights: {}, question: {} } };

const highlightSlice = createSlice({
  name: 'highlight',
  initialState: initialHighlightState,
  reducers: {
    updateHighlights(state, action) {
      const { nodeId, highlights, question } = action.payload;
      state.highlightsByNode.highlights[nodeId] = highlights;
      state.highlightsByNode.question[nodeId] = question;
    },
    resetHighlights() {
      return initialHighlightState; // Simply return the initial state
    },
  },
});

export const highlightsActions = highlightSlice.actions;

const store = configureStore({
  reducer: {
    highlights: highlightSlice.reducer,
  },
});

export default store;
