import React, { useEffect, useState } from 'react';
import './SynthesisModal.css';
import { motion, useAnimationControls } from 'framer-motion';

const SynthesisedHighlight = ({ node, data }) => {
  const [showHighlights, setShowHighlights] = useState(false);
  const highlightControls = useAnimationControls();

  useEffect(() => {
    highlightControls.start({
      opacity: [0.9, 1],
      filter: ['blur(1.2px)', 'blur(0px)'],
      y: [-5.5, 0],
      scaleY: [0.98, 1],
      transition: { type: 'spring', bounce: 0, duration: 1 },
    });
  }, [showHighlights]);

  const highlightSummary = data?.highlightSummaries?.find(
    (item) => item.nodeId === node.nodeId
  ) || { summary: 'No summary available' };

  return (
    <div>
      <motion.button
        className="synthesised-highlight-button"
        id={showHighlights ? 'synthesised-highlight-button-active' : ''}
        onClick={() => setShowHighlights(!showHighlights)}
      >
        <p>{showHighlights ? 'Hide highlights' : 'Show highlights'}</p>
        {showHighlights ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clip-path="url(#clip0_603_7044)">
              <path
                d="M6.09047 14.475C6.38297 14.7675 6.85547 14.7675 7.14797 14.475L9.00047 12.6225L10.853 14.475C11.1455 14.7675 11.618 14.7675 11.9105 14.475C12.203 14.1825 12.203 13.71 11.9105 13.4175L9.53297 11.04C9.24047 10.7475 8.76797 10.7475 8.47547 11.04L6.09797 13.4175C5.79797 13.7025 5.79797 14.1825 6.09047 14.475ZM11.9105 3.52504C11.618 3.23254 11.1455 3.23254 10.853 3.52504L9.00047 5.37754L7.14797 3.52504C6.85547 3.23254 6.38297 3.23254 6.09047 3.52504C5.79797 3.81754 5.79797 4.29754 6.09047 4.59004L8.46797 6.96754C8.76047 7.26004 9.23297 7.26004 9.52547 6.96754L11.903 4.59004C12.203 4.29754 12.203 3.81754 11.9105 3.52504Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_603_7044">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clip-path="url(#clip0_613_40155)">
              <path
                d="M9 4.37254L10.845 6.21754C11.1375 6.51004 11.61 6.51004 11.9025 6.21754C12.195 5.92504 12.195 5.45254 11.9025 5.16004L9.525 2.77504C9.2325 2.48254 8.76 2.48254 8.4675 2.77504L6.09 5.16004C5.7975 5.45254 5.7975 5.92504 6.09 6.21754C6.3825 6.51004 6.855 6.51004 7.1475 6.21754L9 4.37254ZM9 13.6275L7.155 11.7825C6.8625 11.49 6.39 11.49 6.0975 11.7825C5.805 12.075 5.805 12.5475 6.0975 12.84L8.475 15.225C8.7675 15.5175 9.24 15.5175 9.5325 15.225L11.91 12.8475C12.2025 12.555 12.2025 12.0825 11.91 11.79C11.6175 11.4975 11.145 11.4975 10.8525 11.79L9 13.6275Z"
                fill="#575073"
              />
            </g>
            <defs>
              <clipPath id="clip0_613_40155">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </motion.button>
      <motion.div key={node.nodeId} id="synthesis-highlights-list-object">
        <h4>{node.question}</h4>
        <motion.div animate={highlightControls}>
          {showHighlights ? (
            <ul>
              {node.highlights.map((highlight, index) => (
                <li id="synthesis-highlight-object" key={index}>
                  {highlight.text}
                </li>
              ))}
            </ul>
          ) : (
            <p>
              {highlightSummary
                ? highlightSummary?.summary
                : 'No summary found'}
            </p>
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SynthesisedHighlight;
