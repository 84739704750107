import React, { useEffect, useState } from 'react';
import './Modal.css';
import './CreateModal.css';
import NewSpinner from './NewSpinner';
import { motion, useAnimationControls } from 'framer-motion';
import { TokenAnnotator } from 'react-text-annotate';
import { useSelector, useDispatch } from 'react-redux';
import { highlightsActions } from './store/store';

// Adding random comment

const Modal = ({
  responseObject,
  setShowDetailModal,
  activeSubQuestion,
  isLoadingContent,
  nodes,
  fetchedUrls,
}) => {
  const [expandSummaryButtonActive, setExpandSummaryButtonActive] =
    useState(false);
  const [supplementaryReadings, setSupplementaryReadings] = useState(true);
  const [activeNodeId, setActiveNodeId] = useState('');

  useEffect(() => {
    const activeNode = nodes.find((node) => node.text === activeSubQuestion);
    if (activeNode) {
      setActiveNodeId(activeNode.id);
    } else {
      console.error(
        'No active node found for the question:',
        activeSubQuestion
      );
      // Handle the absence of an active node appropriately
    }
  }, [activeSubQuestion]);

  const dispatch = useDispatch();
  const highlights = useSelector(
    (state) =>
      state.highlights.highlightsByNode.highlights[activeNodeId] || {
        summary: [],
        expandedSummary: [],
      }
  );

  const controls = useAnimationControls();
  const urlsControls = useAnimationControls();

  useEffect(() => {
    controls.start({
      opacity: [0.9, 1],
      filter: ['blur(1.2px)', 'blur(0px)'],
      y: [-5.5, 0],
      scaleY: [0.98, 1],
      transition: { type: 'spring', bounce: 0, duration: 1 },
    });
  }, [expandSummaryButtonActive]);

  useEffect(() => {
    urlsControls.start({
      opacity: [0.9, 1],
      filter: ['blur(1.2px)', 'blur(0px)'],
      y: [-5.5, 0],
      scaleY: [0.98, 1],
      transition: { type: 'spring', bounce: 0, duration: 1 },
    });
  }, [supplementaryReadings]);

  const parseApiResponse = (response) => {
    if (!response || !response.summary || !Array.isArray(response.urls)) {
      // Data not available, return defaults or indicate loading
      return {
        summaryText:
          'Technologies to enhance energy efficiency encompass a variety of sectors, including industrial processes, building design, transportation, and power generation. Prominent advancements include smart grids, LED lighting, high-efficiency heat pumps, and electric vehicles. These technologies not only optimize energy use but also contribute directly to reducing greenhouse gas emissions, aligning with broader climate change mitigation strategies.',
        urls: [
          {
            title: 'A tool to augment human intelligence',
            domain: 'www.nytimes.com',
            url: 'https://www.economist.com/science-and-technology/2023/04/19/large-language-models-ability-to-generate-text-also-lets-them-plan-and-reason',
          },
          {
            title: 'Enabling better problem solving in the world',
            domain: 'www.wikipedia.com',
            url: 'https://www.economist.com/science-and-technology/2023/04/19/large-language-models-ability-to-generate-text-also-lets-them-plan-and-reason',
          },
          {
            title: 'Enhancing human cognition',
            domain: 'www.ft.com',
            url: 'https://www.economist.com/science-and-technology/2023/04/19/large-language-models-ability-to-generate-text-also-lets-them-plan-and-reason',
          },
          {
            title: 'Sparking progress towards societal challenges',
            domain: 'www.nature.com',
            url: 'https://www.economist.com/science-and-technology/2023/04/19/large-language-models-ability-to-generate-text-also-lets-them-plan-and-reason',
          },
          {
            title: 'Improving your research',
            domain: 'www.wsj.com',
            url: 'https://www.economist.com/science-and-technology/2023/04/19/large-language-models-ability-to-generate-text-also-lets-them-plan-and-reason',
          },
        ],
        expandedSummaryText:
          'Technologies to enhance energy efficiency encompass a variety of sectors, including industrial processes, building design, transportation, and power generation. Prominent advancements include smart grids, LED lighting, high-efficiency heat pumps, and electric vehicles. These technologies not only optimize energy use but also contribute directly to reducing greenhouse gas emissions, aligning with broader climate change mitigation strategies.',
      };
    }
    const { summary, urls, expandedSummary } = response;
    return {
      summaryText: summary.text,
      urls,
      expandedSummaryText: expandedSummary.text,
    };
  };

  const { summaryText, urls, expandedSummaryText } =
    parseApiResponse(responseObject);

  const tokens = expandSummaryButtonActive
    ? expandedSummaryText.split(' ')
    : summaryText.split(' ');

  const handleHighlightsChange = (newHighlights) => {
    const key = expandSummaryButtonActive ? 'expandedSummary' : 'summary';
    const newTextHighlights = newHighlights.map((hl) => ({
      text: tokens.slice(hl.start, hl.end).join(' '),
      ...hl,
    }));

    // console.log('Dispatching new highlights for:', key, newTextHighlights);
    dispatch(
      highlightsActions.updateHighlights({
        nodeId: activeNodeId,
        highlights: {
          ...highlights,
          [key]: newTextHighlights,
        },
        question: activeSubQuestion,
      })
    );
  };

  return (
    <>
      <motion.div
        id="detail-modal"
        initial={{ opacity: 0, scale: 0.85 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', bounce: 0, duration: 0.25 }}
        exit={{
          opacity: 0,
          scale: 0.85,
          transition: { type: 'spring', duration: 0.25, bounce: 0 },
        }}
      >
        <div id="detail-modal-header"></div>

        {isLoadingContent && (
          <div id="modal-spinner">
            <NewSpinner />
          </div>
        )}

        <div id="modal-content">
          <motion.div id="modal-summary-container">
            <motion.h2
              id="modal-summary-title"
              initial={{
                filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
              }}
              animate={{
                filter: 'blur(0px)',
              }}
              transition={{
                type: 'spring',
                bounce: 0,
                duration: isLoadingContent ? 8 : 0,
              }}
            >
              {activeSubQuestion}
            </motion.h2>
            <motion.div id="modal-summary-section">
              <motion.button
                initial={{
                  filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
                }}
                animate={{
                  filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
                }}
                transition={{
                  type: 'spring',
                  bounce: 0,
                  duration: 2,
                }}
                className="modal-summary-section-button"
                id={
                  expandSummaryButtonActive
                    ? 'modal-summary-section-button-active'
                    : ''
                }
                onClick={() =>
                  setExpandSummaryButtonActive(!expandSummaryButtonActive)
                }
              >
                <p>{expandSummaryButtonActive ? 'Read less' : 'Read more'}</p>
                {expandSummaryButtonActive ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_603_7044)">
                      <path
                        d="M6.09047 14.475C6.38297 14.7675 6.85547 14.7675 7.14797 14.475L9.00047 12.6225L10.853 14.475C11.1455 14.7675 11.618 14.7675 11.9105 14.475C12.203 14.1825 12.203 13.71 11.9105 13.4175L9.53297 11.04C9.24047 10.7475 8.76797 10.7475 8.47547 11.04L6.09797 13.4175C5.79797 13.7025 5.79797 14.1825 6.09047 14.475ZM11.9105 3.52504C11.618 3.23254 11.1455 3.23254 10.853 3.52504L9.00047 5.37754L7.14797 3.52504C6.85547 3.23254 6.38297 3.23254 6.09047 3.52504C5.79797 3.81754 5.79797 4.29754 6.09047 4.59004L8.46797 6.96754C8.76047 7.26004 9.23297 7.26004 9.52547 6.96754L11.903 4.59004C12.203 4.29754 12.203 3.81754 11.9105 3.52504Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_603_7044">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_613_40155)">
                      <path
                        d="M9 4.37254L10.845 6.21754C11.1375 6.51004 11.61 6.51004 11.9025 6.21754C12.195 5.92504 12.195 5.45254 11.9025 5.16004L9.525 2.77504C9.2325 2.48254 8.76 2.48254 8.4675 2.77504L6.09 5.16004C5.7975 5.45254 5.7975 5.92504 6.09 6.21754C6.3825 6.51004 6.855 6.51004 7.1475 6.21754L9 4.37254ZM9 13.6275L7.155 11.7825C6.8625 11.49 6.39 11.49 6.0975 11.7825C5.805 12.075 5.805 12.5475 6.0975 12.84L8.475 15.225C8.7675 15.5175 9.24 15.5175 9.5325 15.225L11.91 12.8475C12.2025 12.555 12.2025 12.0825 11.91 11.79C11.6175 11.4975 11.145 11.4975 10.8525 11.79L9 13.6275Z"
                        fill="#575073"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_613_40155">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </motion.button>
              <motion.div
                id="modal-summary-text-container"
                initial={{
                  opacity: isLoadingContent ? 0 : 1,
                  filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
                }}
                animate={{
                  filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
                  opacity: isLoadingContent ? [0.85, 0.9] : 1,
                }}
                transition={{
                  type: 'spring',
                  bounce: 0,
                  duration: 3,
                  repeat: isLoadingContent ? Infinity : null,
                }}
              >
                <motion.p id="modal-summary-text" animate={controls}>
                  <TokenAnnotator
                    tokens={tokens}
                    value={
                      highlights[
                        expandSummaryButtonActive
                          ? 'expandedSummary'
                          : 'summary'
                      ]
                    }
                    onChange={handleHighlightsChange}
                    getSpan={(span) => ({
                      ...span,
                      color: 'rgba(242, 226, 194, 0.5)',
                    })}
                  />
                </motion.p>
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div id="modal-urls-container">
            <motion.div
              id="modal-urls-buttons"
              initial={{
                filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
              }}
              animate={{
                filter: 'blur(0px)',
              }}
              transition={{
                type: 'spring',
                bounce: 0,
                duration: isLoadingContent ? 8 : 0,
              }}
            >
              <motion.button
                className={
                  supplementaryReadings
                    ? 'modal-url-button-active'
                    : 'modal-url-button'
                }
                onClick={() => setSupplementaryReadings(!supplementaryReadings)}
              >
                <p>Supplementary readings</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M10.6275 3.75L14.25 7.3725V14.25H3.75V3.75H10.6275ZM10.6275 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V7.3725C15.75 6.975 15.5925 6.5925 15.3075 6.315L11.685 2.6925C11.4075 2.4075 11.025 2.25 10.6275 2.25ZM5.25 11.25H12.75V12.75H5.25V11.25ZM5.25 8.25H12.75V9.75H5.25V8.25ZM5.25 5.25H10.5V6.75H5.25V5.25Z"
                    fill={supplementaryReadings ? 'white' : '#505D73'}
                  />
                </svg>
              </motion.button>
              <motion.button
                className={
                  supplementaryReadings
                    ? 'modal-url-button'
                    : 'modal-url-button-active'
                }
                onClick={() => setSupplementaryReadings(!supplementaryReadings)}
              >
                <p>Highlights</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1136_17103)">
                    <path
                      d="M15 8.25H3C2.5875 8.25 2.25 8.5875 2.25 9C2.25 9.4125 2.5875 9.75 3 9.75H15C15.4125 9.75 15.75 9.4125 15.75 9C15.75 8.5875 15.4125 8.25 15 8.25ZM3 13.5H10.5C10.9125 13.5 11.25 13.1625 11.25 12.75C11.25 12.3375 10.9125 12 10.5 12H3C2.5875 12 2.25 12.3375 2.25 12.75C2.25 13.1625 2.5875 13.5 3 13.5ZM15 4.5H3C2.5875 4.5 2.25 4.8375 2.25 5.25V5.2575C2.25 5.67 2.5875 6.0075 3 6.0075H15C15.4125 6.0075 15.75 5.67 15.75 5.2575V5.25C15.75 4.8375 15.4125 4.5 15 4.5Z"
                      fill={supplementaryReadings ? '#505D73' : 'white'}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1136_17103">
                      <rect
                        width="18"
                        height="18"
                        fill={supplementaryReadings ? '#505D73' : 'white'}
                      />
                    </clipPath>
                  </defs>
                </svg>
              </motion.button>
            </motion.div>
            <motion.div id="modal-readings-container" animate={urlsControls}>
              {supplementaryReadings ? (
                <motion.ul id="modal-url">
                  {fetchedUrls.map((urlObj, index) => (
                    <motion.a
                      key={index}
                      id="modal-url-object"
                      href={isLoadingContent ? null : urlObj.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      initial={{
                        opacity: isLoadingContent ? 0 : 1,
                        filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
                        y: isLoadingContent ? -2 : 0,
                      }}
                      animate={{
                        filter: isLoadingContent ? 'blur(5px)' : 'blur(0px)',
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        type: 'spring',
                        bounce: 0,
                        duration: 3,
                        delay: isLoadingContent ? index * 1.2 : 0,
                      }}
                    >
                      <div id="modal-url-object">
                        <motion.li id="modal-url-reading" key={index}>
                          <p id="modal-url-object-title">{urlObj.title}</p>
                          <p id="modal-url-object-domain">{urlObj.domain}</p>
                        </motion.li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_1100_8616)">
                            <path
                              d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"
                              fill="rgba(87, 80, 115, 0.9)"
                              fill-opacity="0.9"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1100_8616">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </motion.a>
                  ))}
                </motion.ul>
              ) : (
                <ul id="highlights-container">
                  {[...highlights.summary, ...highlights.expandedSummary]
                    .length === 0 ? (
                    <li id="highlight-object" style={{ opacity: 0.6 }}>
                      <p>Information you highlight will show up here.</p>
                    </li>
                  ) : (
                    [...highlights.summary, ...highlights.expandedSummary].map(
                      (highlight, index) => (
                        <li id="highlight-object" key={index}>
                          <p>{highlight.text}</p>
                        </li>
                      )
                    )
                  )}
                </ul>
              )}
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
      <motion.div
        id="create-exploration-modal-blur"
        onClick={() => setShowDetailModal(false)}
        exit={{
          opacity: 0,
          transition: { type: 'spring', duration: 0.25, bounce: 0 },
        }}
      ></motion.div>
    </>
  );
};

export default Modal;
