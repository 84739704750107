import React, { useEffect, useState } from 'react';
import './CreateModal.css';
import { AnimatePresence, motion } from 'framer-motion';

const CreateModal = ({
  setContext,
  setQuestion,
  handleQuestionSubmit,
  question,
  context,
  setShowModal,
  setNodes,
  nodes,
}) => {
  const [submitFlag, setSubmitFlag] = useState(false);

  useEffect(() => {
    if (submitFlag && nodes.length === 0) {
      // Checks if the flag is true and nodes are cleared
      handleQuestionSubmit().then(() => {
        setSubmitFlag(false); // Reset flag after submission
      });
    }
  }, [nodes, submitFlag]); // Dependency array includes nodes and submitFlag

  const createHandler = (event) => {
    // Check if the event is a keydown and the key is 'Enter'
    if (event.type === 'keydown' && event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission
      if (question.trim() !== '') {
        setNodes([]); // Clear nodes only if question is not empty
        setSubmitFlag(true);
      }
    }

    // For click events, just check if the question is not empty
    if (event.type === 'click' && question.trim() !== '') {
      setNodes([]);
      setSubmitFlag(true);
    }
  };

  return (
    <>
      <motion.div
        key="createmodal"
        id="create-exploration-modal"
        initial={{ opacity: 0, scale: 0.85 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', bounce: 0, duration: 0.25 }}
        exit={{
          opacity: 0,
          scale: 0.85,
          transition: { type: 'spring', duration: 0.25, bounce: 0 },
        }}
      >
        <div id="create-exploration-modal-header"></div>
        <div id="create-exploration-modal-content">
          <h4>Create a new exploration</h4>
          <div id="forms">
            <form id="main-question-form">
              <label>Main research question</label>
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="E.g: What are the key design/UX challenges in developing intuitive web applications adopting generative AI?"
                onKeyDown={createHandler}
              />
            </form>
            <form id="background-question-form">
              <label>Background context</label>
              <input
                type="text"
                value={context}
                onChange={(e) => setContext(e.target.value)}
                placeholder="E.g: I'm a UX researcher at Google exploring how AI can better improve the value of our suite of web tools like Docs and Slides"
                onKeyDown={createHandler}
              />
            </form>
          </div>
          <motion.button
            id="create-exploration-button"
            onClick={createHandler}
            whileHover={{
              scale: 1.03,
              transition: { type: 'spring', duration: 0.4, bounce: 0 },
            }}
          >
            <p>Create</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_986_57900)">
                <path
                  d="M3 17.2501V21.0001H6.75L17.81 9.94006L14.06 6.19006L3 17.2501ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006V7.04006Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_986_57900">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </motion.button>
        </div>
      </motion.div>
      <motion.div
        id="create-exploration-modal-blur"
        onClick={() => setShowModal(false)}
        exit={{
          opacity: 0,
          transition: { type: 'spring', duration: 0.25, bounce: 0 },
        }}
      ></motion.div>
    </>
  );
};

export default CreateModal;
