import React, { useEffect, useState } from 'react';
import './SynthesisModal.css';
import { motion, useAnimationControls } from 'framer-motion';

const InterviewGuideCard = ({ interviewType, description, content }) => {
  const [showExpandedContent, setShowExpandedContent] = useState(false);

  const controls = useAnimationControls();
  const divControls = useAnimationControls();

  useEffect(() => {
    controls.start({
      opacity: [0.9, 1],
      filter: ['blur(1.2px)', 'blur(0px)'],
      y: [-5.5, 0],
      scaleY: [0.98, 1],
      transition: { type: 'spring', bounce: 0, duration: 1 },
    });
  }, [showExpandedContent]);

  // useEffect(() => {
  //   divControls.start({
  //     opacity: [0.9, 1],
  //     filter: ['blur(1.2px)', 'blur(0px)'],
  //     scaleY: [0.98, 1],
  //     transition: { type: 'spring', bounce: 0, duration: 0.5 },
  //   });
  // }, [showExpandedContent]);

  return (
    <motion.div
      className="synthesis-modal-url-object"
      id={showExpandedContent && 'synthesis-modal-url-object-active'}
      onClick={() => setShowExpandedContent(!showExpandedContent)}
      animate={controls}
    >
      <motion.li id="synthesis-modal-url-card" key={Math.random()}>
        <h4>{interviewType}</h4>
        <motion.div id="synthesis-modal-interview-content">
          {showExpandedContent ? (
            <>
              {content.map((stakeholder, index) => (
                <div id="synthesis-modal-interview-stakeholder">
                  <p>{`${index + 1}. ${stakeholder.stakeholder}`}</p>
                  <div id="synthesis-modal-interview-content-divider"></div>
                  <ul>
                    {stakeholder.questions.map((question) => (
                      <li>{question}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          ) : (
            <p>{description}</p>
          )}
        </motion.div>
      </motion.li>
      {showExpandedContent ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1183_9486)">
            <path
              d="M8.12001 19.2997C8.51001 19.6897 9.14001 19.6897 9.53001 19.2997L12 16.8297L14.47 19.2997C14.86 19.6897 15.49 19.6897 15.88 19.2997C16.27 18.9097 16.27 18.2797 15.88 17.8897L12.71 14.7197C12.32 14.3297 11.69 14.3297 11.3 14.7197L8.13001 17.8897C7.73001 18.2697 7.73001 18.9097 8.12001 19.2997ZM15.88 4.69973C15.49 4.30973 14.86 4.30973 14.47 4.69973L12 7.16973L9.53001 4.69973C9.14001 4.30973 8.51001 4.30973 8.12001 4.69973C7.73001 5.08973 7.73001 5.72973 8.12001 6.11973L11.29 9.28973C11.68 9.67973 12.31 9.67973 12.7 9.28973L15.87 6.11973C16.27 5.72973 16.27 5.08973 15.88 4.69973Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1183_9486">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1228_603)">
            <path
              d="M12 5.82973L14.46 8.28973C14.85 8.67973 15.48 8.67973 15.87 8.28973C16.26 7.89973 16.26 7.26973 15.87 6.87973L12.7 3.69973C12.31 3.30973 11.68 3.30973 11.29 3.69973L8.12001 6.87973C7.73001 7.26973 7.73001 7.89973 8.12001 8.28973C8.51001 8.67973 9.14001 8.67973 9.53001 8.28973L12 5.82973ZM12 18.1697L9.54001 15.7097C9.15001 15.3197 8.52001 15.3197 8.13001 15.7097C7.74001 16.0997 7.74001 16.7297 8.13001 17.1197L11.3 20.2997C11.69 20.6897 12.32 20.6897 12.71 20.2997L15.88 17.1297C16.27 16.7397 16.27 16.1097 15.88 15.7197C15.49 15.3297 14.86 15.3297 14.47 15.7197L12 18.1697Z"
              fill="#575073"
            />
          </g>
          <defs>
            <clipPath id="clip0_1228_603">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </motion.div>
  );
};

export default InterviewGuideCard;
