import React, { useEffect, useState, useRef } from 'react';
import Draggable from 'react-draggable';
import './Node.css';
import { motion } from 'framer-motion';

const Node = ({
  node,
  onDrag,
  onDoubleClick,
  clickedNodes,
  addChildNode,
  isLoading,
  delay
}) => {
  const [addNodeState, setAddNodeState] = useState(false);
  const [addNodeHoverState, setAddNodeHoverState] = useState(false);
  const [showNodeInput, setShowNodeInput] = useState(false);

  const inputRef = useRef(null);
  const nodeRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showNodeInput]);

  useEffect(() => {
    const handleHoverOutside = (event) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target)) {
        setAddNodeHoverState(false);
      }
    };

    document.addEventListener('mouseover', handleHoverOutside);
    return () => {
      document.removeEventListener('mouseover', handleHoverOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target)) {
        setAddNodeState(false);
        setShowNodeInput(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const processInput = (event) => {
    if (event.key === 'Enter' && inputRef.current.value.trim() !== '') {
      addChildNode(node.id, inputRef.current.value);
      setShowNodeInput(false);
      setAddNodeState(false);
    }
  };

  const colorMap = {
    'sub-1': '#A14666',
    'sub-2': '#839AC3',
    'sub-3': '#D96C3C',
    'sub-4': '#947DC5',
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9, filter: 'blur(10px)' }}
      animate={{
        opacity: 1,
        scale: 1,
        filter: isLoading ? 'blur(10px)' : 'blur(0px)',
      }}
      transition={{ type: 'spring', bounce: 0, duration: 0.3, delay: isLoading ? delay : 0 }}
    >
      <Draggable
        defaultPosition={node.position}
        onStop={(e, data) => onDrag(node.id, data.x, data.y)}
      >
        <div
          ref={nodeRef}
          className="node"
          id={
            (addNodeState && 'add-subnode-active') ||
            (addNodeHoverState && 'add-subnode-hover')
          }
          style={{
            backgroundColor: colorMap[node.topmostSubQuestionId] || '#19113D',
          }}
          onClick={isLoading ? null : () => setAddNodeState(!addNodeState)}
          onMouseOver={isLoading ? null : () => setAddNodeHoverState(true)}
        >
          {clickedNodes.includes(node.id) && (
            <div id="read-indicator">Explored</div>
          )}
          <div id="node-container">
            <div id="node-text">
              <p id="node-text-header">
                {node.number} {node.header}
              </p>
              <p>{node.text}</p>
            </div>
            <div
              id="node-expand-button"
              onClick={isLoading ? null : () => onDoubleClick(node.id)}
            >
              <p
                style={{
                  color: colorMap[node.topmostSubQuestionId] || '#19113D',
                }}
              >
                Explore
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 36 37"
                fill="none"
              >
                <g clip-path="url(#clip0_986_43447)">
                  <path
                    d="M23.25 21.5H22.065L21.645 21.095C23.445 18.995 24.375 16.13 23.865 13.085C23.16 8.91502 19.68 5.58502 15.48 5.07502C9.13503 4.29502 3.81003 9.63502 4.57503 15.98C5.08503 20.18 8.41503 23.66 12.585 24.365C15.63 24.875 18.495 23.945 20.595 22.145L21 22.565V23.75L27.39 30.125C28.005 30.74 28.995 30.74 29.61 30.125L29.625 30.11C30.24 29.495 30.24 28.505 29.625 27.89L23.25 21.5ZM14.25 21.5C10.515 21.5 7.50003 18.485 7.50003 14.75C7.50003 11.015 10.515 8.00002 14.25 8.00002C17.985 8.00002 21 11.015 21 14.75C21 18.485 17.985 21.5 14.25 21.5ZM14.25 11C13.83 11 13.5 11.33 13.5 11.75V14H11.25C10.83 14 10.5 14.33 10.5 14.75C10.5 15.17 10.83 15.5 11.25 15.5H13.5V17.75C13.5 18.17 13.83 18.5 14.25 18.5C14.67 18.5 15 18.17 15 17.75V15.5H17.25C17.67 15.5 18 15.17 18 14.75C18 14.33 17.67 14 17.25 14H15V11.75C15 11.33 14.67 11 14.25 11Z"
                    fill={colorMap[node.topmostSubQuestionId] || '#19113D'}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_986_43447">
                    <rect
                      width="28"
                      height="28"
                      fill={colorMap[node.topmostSubQuestionId] || '#19113D'}
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          {addNodeState && (
            <motion.button
              id="add-subnode-button"
              onClick={isLoading ? null : () => setShowNodeInput(true)}
              initial={{ opacity: 0, scale: 0.8, y: -10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
              whileHover={{
                scale: 1.1,
                transition: { type: 'spring', bounce: 0, duration: 0.3 },
              }}
            >
              +
            </motion.button>
          )}
          {showNodeInput && (
            <motion.input
              id="add-childnode-input"
              placeholder="Enter question to further explore"
              ref={inputRef}
              onKeyDown={processInput}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
            ></motion.input>
          )}
        </div>
      </Draggable>
    </motion.div>
  );
};

export default Node;
