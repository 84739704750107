import React from 'react';
import './NewSpinner.css';

const NewSpinner = () => {
  return (
    <svg
      class="spinner"
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="path"
        fill="none"
        stroke-width="5"
        stroke-linecap="square"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  );
};

export default NewSpinner;
