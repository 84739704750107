import React, { useEffect, useState } from 'react';
import './SynthesisModal.css';
import NewSpinner from './NewSpinner';
import { motion, useAnimationControls } from 'framer-motion';
import SynthesisedHighlight from './SynthesisedHighlight';
import InterviewGuideCard from './InterviewGuideCard.js';
import LimitationsCard from './LimitationsCard.js';

const SynthesisModal = ({
  mainQuestion,
  setShowSynthesisModal,
  reduxStoreData,
  data,
  limitations,
  interviewData,
  isLoadingSynthesis,
  isLoadingLimitations,
  isLoadingInterviewData,
}) => {
  const [showExpandedSummary, setShowExpandedSummary] = useState(false);
  const [showLimitations, setShowLimitations] = useState(true);
  const controls = useAnimationControls();
  const limitationsControls = useAnimationControls();

  useEffect(() => {
    controls.start({
      opacity: [0.9, 1],
      filter: ['blur(1.2px)', 'blur(0px)'],
      y: [-5.5, 0],
      scaleY: [0.98, 1],
      transition: { type: 'spring', bounce: 0, duration: 1 },
    });
  }, [showExpandedSummary]);

  useEffect(() => {
    limitationsControls.start({
      opacity: [0.9, 1],
      filter: ['blur(1.2px)', 'blur(0px)'],
      y: [-5.5, 0],
      scaleY: [0.98, 1],
      transition: { type: 'spring', bounce: 0, duration: 1 },
    });
  }, [showLimitations]);

  return (
    <>
      <motion.div
        id="synthesis-modal"
        initial={{ opacity: 0, scale: 0.85 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', bounce: 0, duration: 0.25 }}
        exit={{
          opacity: 0,
          scale: 0.85,
          transition: { type: 'spring', duration: 0.25, bounce: 0 },
        }}
      >
        <div id="synthesis-modal-header"></div>
        {isLoadingSynthesis && (
          <div id="modal-spinner">
            <NewSpinner />
          </div>
        )}

        <motion.div id="synthesis-modal-content">
          {reduxStoreData.length !== 0 ? (
            <>
              {' '}
              <motion.div id="synthesis-modal-summary-container">
                <div id="synthesis-modal-summary-header">
                  <h2 id="synthesis-modal-summary-title">
                    Synthesis: {mainQuestion || 'Main Question'}
                  </h2>
                  <h4>
                    A curated synthesis of your highlights across all nodes.
                  </h4>
                </div>
                <motion.div
                  id="synthesis-modal-summary-section"
                  initial={{
                    opacity: isLoadingSynthesis ? 0 : 1,
                    filter: isLoadingSynthesis ? 'blur(5px)' : 'blur(0px)',
                  }}
                  animate={{
                    filter: isLoadingSynthesis ? 'blur(5px)' : 'blur(0px)',
                    opacity: isLoadingSynthesis ? [0.85, 0.9] : 1,
                  }}
                  transition={{
                    type: 'spring',
                    bounce: 0,
                    duration: 3,
                    repeat: isLoadingSynthesis ? Infinity : null,
                  }}
                >
                  <div>
                    <motion.button
                      className="synthesis-modal-summary-section-button"
                      id={
                        showExpandedSummary
                          ? 'modal-summary-section-button-active'
                          : ''
                      }
                      onClick={() =>
                        setShowExpandedSummary(!showExpandedSummary)
                      }
                    >
                      <p>{showExpandedSummary ? 'Read less' : 'Read more'}</p>
                      {showExpandedSummary ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_603_7044)">
                            <path
                              d="M6.09047 14.475C6.38297 14.7675 6.85547 14.7675 7.14797 14.475L9.00047 12.6225L10.853 14.475C11.1455 14.7675 11.618 14.7675 11.9105 14.475C12.203 14.1825 12.203 13.71 11.9105 13.4175L9.53297 11.04C9.24047 10.7475 8.76797 10.7475 8.47547 11.04L6.09797 13.4175C5.79797 13.7025 5.79797 14.1825 6.09047 14.475ZM11.9105 3.52504C11.618 3.23254 11.1455 3.23254 10.853 3.52504L9.00047 5.37754L7.14797 3.52504C6.85547 3.23254 6.38297 3.23254 6.09047 3.52504C5.79797 3.81754 5.79797 4.29754 6.09047 4.59004L8.46797 6.96754C8.76047 7.26004 9.23297 7.26004 9.52547 6.96754L11.903 4.59004C12.203 4.29754 12.203 3.81754 11.9105 3.52504Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_603_7044">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_613_40155)">
                            <path
                              d="M9 4.37254L10.845 6.21754C11.1375 6.51004 11.61 6.51004 11.9025 6.21754C12.195 5.92504 12.195 5.45254 11.9025 5.16004L9.525 2.77504C9.2325 2.48254 8.76 2.48254 8.4675 2.77504L6.09 5.16004C5.7975 5.45254 5.7975 5.92504 6.09 6.21754C6.3825 6.51004 6.855 6.51004 7.1475 6.21754L9 4.37254ZM9 13.6275L7.155 11.7825C6.8625 11.49 6.39 11.49 6.0975 11.7825C5.805 12.075 5.805 12.5475 6.0975 12.84L8.475 15.225C8.7675 15.5175 9.24 15.5175 9.5325 15.225L11.91 12.8475C12.2025 12.555 12.2025 12.0825 11.91 11.79C11.6175 11.4975 11.145 11.4975 10.8525 11.79L9 13.6275Z"
                              fill="#575073"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_613_40155">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </motion.button>
                    <motion.div id="synthesis-modal-summary-text-container">
                      <h4>{data.keyIdea?.text}</h4>

                      <motion.p animate={controls}>
                        {showExpandedSummary
                          ? data.expandedSummary?.text
                          : data.summary?.text}
                      </motion.p>
                    </motion.div>
                  </div>
                  <div>
                    <motion.div id="synthesis-highlights-container">
                      {reduxStoreData.map((node) => (
                        <SynthesisedHighlight
                          key={node.nodeId}
                          node={node}
                          data={data}
                        />
                      ))}
                    </motion.div>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div id="synthesis-modal-limitations-container">
                <motion.div id="synthesis-modal-urls-buttons">
                  <motion.button
                    className="synthesis-modal-url-button"
                    onClick={() => setShowLimitations(true)}
                    id={showLimitations && 'synthesis-modal-url-button-active'}
                  >
                    <p>Limitations & gaps</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1182_8728)">
                        <path
                          d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM8.25 12H9.75V13.5H8.25V12ZM9.4575 4.53C7.9125 4.305 6.5475 5.2575 6.135 6.6225C6 7.0575 6.33 7.5 6.7875 7.5H6.9375C7.245 7.5 7.4925 7.2825 7.5975 6.9975C7.8375 6.33 8.55 5.8725 9.3225 6.0375C10.035 6.1875 10.56 6.885 10.5 7.6125C10.425 8.6175 9.285 8.835 8.6625 9.7725C8.6625 9.78 8.655 9.78 8.655 9.7875C8.6475 9.8025 8.64 9.81 8.6325 9.825C8.565 9.9375 8.4975 10.065 8.445 10.2C8.4375 10.2225 8.4225 10.2375 8.415 10.26C8.4075 10.275 8.4075 10.29 8.4 10.3125C8.31 10.5675 8.25 10.875 8.25 11.25H9.75C9.75 10.935 9.8325 10.6725 9.96 10.4475C9.975 10.425 9.9825 10.4025 9.9975 10.38C10.0575 10.275 10.1325 10.1775 10.2075 10.0875C10.215 10.08 10.2225 10.065 10.23 10.0575C10.305 9.9675 10.3875 9.885 10.4775 9.8025C11.1975 9.12 12.1725 8.565 11.97 7.1325C11.79 5.8275 10.7625 4.725 9.4575 4.53Z"
                          fill={showLimitations ? 'white' : '#505D73'}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1182_8728">
                          <rect
                            width="18"
                            height="18"
                            fill={showLimitations ? 'white' : '#505D73'}
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </motion.button>
                  <motion.button
                    className={'synthesis-modal-url-button'}
                    id={!showLimitations && 'synthesis-modal-url-button-active'}
                    onClick={() => setShowLimitations(false)}
                  >
                    <p>Interview guide</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1182_8736)">
                        <path
                          d="M12.375 9C13.41 9 14.2425 8.16 14.2425 7.125C14.2425 6.09 13.41 5.25 12.375 5.25C11.34 5.25 10.5 6.09 10.5 7.125C10.5 8.16 11.34 9 12.375 9ZM6.75 8.25C7.995 8.25 8.9925 7.245 8.9925 6C8.9925 4.755 7.995 3.75 6.75 3.75C5.505 3.75 4.5 4.755 4.5 6C4.5 7.245 5.505 8.25 6.75 8.25ZM12.375 10.5C11.0025 10.5 8.25 11.19 8.25 12.5625V13.5C8.25 13.9125 8.5875 14.25 9 14.25H15.75C16.1625 14.25 16.5 13.9125 16.5 13.5V12.5625C16.5 11.19 13.7475 10.5 12.375 10.5ZM6.75 9.75C5.0025 9.75 1.5 10.6275 1.5 12.375V13.5C1.5 13.9125 1.8375 14.25 2.25 14.25H6.75V12.5625C6.75 11.925 6.9975 10.8075 8.5275 9.96C7.875 9.825 7.245 9.75 6.75 9.75Z"
                          fill={!showLimitations ? 'white' : '#505D73'}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1182_8736">
                          <rect
                            width="18"
                            height="18"
                            fill={!showLimitations ? 'white' : '#505D73'}
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </motion.button>
                </motion.div>
                <motion.div id="synthesis-modal-limitations-content">
                  <div id="synthesis-modal-limitations-description">
                    <motion.p id="synthesis-modal-limitations-header">
                      {showLimitations
                        ? 'Research limitations & gaps'
                        : 'Helpful information to gather through interviews'}
                    </motion.p>
                    <motion.p id="synthesis-modal-limitations-text">
                      {showLimitations
                        ? `Here are some key limitations and gaps in your currently
                  highlighted content you should consider to gain a better
                  overview of your main research question.`
                        : `Secondary literature and AI-generated advice will only get you so far. To better address limitations and gaps in your research, it may be valuable to conduct qualitative interviews with key stakeholders as outlined below:`}
                    </motion.p>
                  </div>
                  <motion.ul id="synthesis-modal-url">
                    {showLimitations ? (
                      <>
                        {limitations.map((limitation, index) => (
                          <motion.div
                            initial={{
                              opacity:
                                isLoadingSynthesis || isLoadingLimitations
                                  ? 0
                                  : 1,
                              filter:
                                isLoadingSynthesis || isLoadingLimitations
                                  ? 'blur(5px)'
                                  : 'blur(0px)',
                              y:
                                isLoadingSynthesis || isLoadingLimitations
                                  ? -2
                                  : 0,
                            }}
                            animate={{
                              filter:
                                isLoadingSynthesis || isLoadingLimitations
                                  ? 'blur(5px)'
                                  : 'blur(0px)',
                              opacity: 1,
                              y: 0,
                            }}
                            transition={{
                              type: 'spring',
                              bounce: 0,
                              duration: 3,
                              delay:
                                isLoadingSynthesis || isLoadingLimitations
                                  ? index * 1.2
                                  : 0,
                            }}
                          >
                            <LimitationsCard
                              key={index}
                              limitationsControls={limitationsControls}
                              title={limitation.title}
                              description={limitation.description}
                            />
                          </motion.div>
                        ))}
                      </>
                    ) : (
                      <>
                        {interviewData.map((interviewObject, index) => (
                          <motion.div
                            initial={{
                              opacity:
                                isLoadingSynthesis || isLoadingInterviewData
                                  ? 0
                                  : 1,
                              filter:
                                isLoadingSynthesis || isLoadingInterviewData
                                  ? 'blur(5px)'
                                  : 'blur(0px)',
                              y:
                                isLoadingSynthesis || isLoadingInterviewData
                                  ? -2
                                  : 0,
                            }}
                            animate={{
                              filter:
                                isLoadingSynthesis || isLoadingInterviewData
                                  ? 'blur(5px)'
                                  : 'blur(0px)',
                              opacity: 1,
                              y: 0,
                            }}
                            transition={{
                              type: 'spring',
                              bounce: 0,
                              duration: 3,
                              delay:
                                isLoadingSynthesis || isLoadingInterviewData
                                  ? index * 1.2
                                  : 0,
                            }}
                          >
                            <InterviewGuideCard
                              key={index}
                              interviewType={interviewObject.interviewType}
                              description={interviewObject.description}
                              content={interviewObject.content}
                            />
                          </motion.div>
                        ))}
                      </>
                    )}
                  </motion.ul>
                </motion.div>
              </motion.div>
            </>
          ) : (
            <div id="synthesis-placeholder-content">
              <p>
                Start highlighting content across nodes to synthesise your
                research.
              </p>
            </div>
          )}
        </motion.div>
      </motion.div>
      <motion.div
        id="create-exploration-modal-blur"
        onClick={() => setShowSynthesisModal(false)}
        exit={{
          opacity: 0,
          transition: { type: 'spring', duration: 0.25, bounce: 0 },
        }}
      ></motion.div>
    </>
  );
};

export default SynthesisModal;
